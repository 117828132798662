import { render, staticRenderFns } from "./HokSelect.vue?vue&type=template&id=6bdc841f&scoped=true"
import script from "./HokSelect.vue?vue&type=script&lang=ts"
export * from "./HokSelect.vue?vue&type=script&lang=ts"
import style0 from "./HokSelect.vue?vue&type=style&index=0&id=6bdc841f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_prettier@3.3.3_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdc841f",
  null
  
)

export default component.exports