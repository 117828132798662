import type { ActionTree } from 'vuex';
import type { APITypeObjectId, APIObjectType } from '@hokify/common';
import type { IRootState } from '~/store';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

export const actions: ActionTree<IRootState, IRootState> = {
	async validateCoupon(
		_,
		{
			organizationId,
			couponCode
		}: { organizationId: APITypeObjectId<APIObjectType.Organization>; couponCode: string }
	): Promise<boolean> {
		const params = new URLSearchParams({ organizationId });

		try {
			this.$axios.$get<void>(
				`/app-api/recruiter/shop/cart/coupon/${couponCode}?${params.toString()}`,
				{ baseURL }
			);
		} catch (error) {
			/* If the organization that we are sending does not exist, 
			we will receive this error from the BE, and we have to rethrow the error. 
			But any other error means the coupon itself didn't 
			pass the validation (coupon expired, not valid, etc.). Therefore, return false */
			if (!(<Error>error).message.toLowerCase().includes('organization')) {
				return false;
			}

			throw error;
		}

		return true;
	}
};

export default actions;
